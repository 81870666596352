import React from "react";
import { Col, Row } from "react-bootstrap";
import { CgCPlusPlus } from "react-icons/cg";
import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiMongodb,
  DiPython,
  DiGit,
  DiJava,
  DiHtml5,
  DiCss3,
} from "react-icons/di";
import {
  SiRedis,
  SiFirebase,
  SiNextdotjs,
  SiSolidity,
  SiTypescript,
  SiExpress,
  SiElasticsearch,
  SiGraphql,
} from "react-icons/si";
import { TbBrandGolang } from "react-icons/tb";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiTypescript />
        <div style={{ fontSize: 25 }}>Typescript</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiJavascript1 />
        <div style={{ fontSize: 25 }}>Javascript</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiReact />
        <div style={{ fontSize: 25 }}>React</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiNextdotjs />
        <div style={{ fontSize: 25 }}>NextJS</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiNodejs />
        <div style={{ fontSize: 25 }}>NodeJS</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiSolidity />
        <div style={{ fontSize: 25 }}>Solidity</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiMongodb />
        <div style={{ fontSize: 25 }}>MongoDB</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiExpress />
        <div style={{ fontSize: 25 }}>Express</div>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiElasticsearch />
        <div style={{ fontSize: 25 }}>Elasticsearch</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiGraphql />
        <div style={{ fontSize: 25 }}>GraphQL</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiGit />
        <div style={{ fontSize: 25 }}>Git</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiFirebase />
        <div style={{ fontSize: 25 }}>Firebase</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiRedis />
        <div style={{ fontSize: 25 }}>Redis</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiHtml5 />
        <div style={{ fontSize: 25 }}>HTML5</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiCss3 />
        <div style={{ fontSize: 25 }}>CSS3</div>
      </Col>
    </Row>
  );
}

export default Techstack;
