import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify", wordSpacing: "-2px" }}>
            Hi Everyone, I am <span class="purple">PHINERA-HORTH Yanel</span>{" "}
            and I'm from <span class="purple">Toulouse in France.</span>
            <br /> I'm a dedicated life grinder, constantly seeking growth.
            <br /> I am an experienced{" "}
            <span class="purple">software engineer</span> with over{" "}
            <span class="purple">6 years</span> in the field, having
            collaborated with major clients such as Airbus in the aerospace
            industry.
            <br /> Additionally, I am a <span class="purple">Web3</span>{" "}
            enthusiast, passionate about new{" "}
            <span class="purple">
              cryptocurrencies and blockchain technologies
            </span>
            . Currently, I am building projects around smart contracts, such as
            voting system, decentralized exchange landing platform, and much
            more.
            <br />
            <br /> Apart from coding, some other activities that I love to do!
          </p>

          <ul>
            <li className="about-activity">
              <ImPointRight /> Calisthenics
            </li>
            <li className="about-activity">
              <ImPointRight /> Cycling
            </li>
            <li className="about-activity">
              <ImPointRight /> Piano
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "The only thing that is doing the thing is doing the thing."{" "}
          </p>
          <footer className="blockquote-footer">Strangest loop</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
