import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiVisualstudiocode,
  SiPostman,
  SiSlack,
  SiVercel,
  SiMacos,
  SiPostgresql,
  SiDocker,
  SiKubernetes,
} from "react-icons/si";

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiMacos />
        <div style={{ fontSize: 25 }}>MacOS</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiVisualstudiocode />
        <div style={{ fontSize: 25 }}>VS Code</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPostman />
        <div style={{ fontSize: 25 }}>Postman</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiSlack />
        <div style={{ fontSize: 25 }}>Slack</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiVercel />
        <div style={{ fontSize: 25 }}>Vercel</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPostgresql />
        <div style={{ fontSize: 25 }}>PostgreSQL</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiDocker />
        <div style={{ fontSize: 25 }}>Docker</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiKubernetes />
        <div style={{ fontSize: 25 }}>Kubernetes</div>
      </Col>
    </Row>
  );
}

export default Toolstack;
